import cls from 'clsx';
import React, { Children } from 'react';
import styles from './SpecialOfferLabelsGroup.module.scss';

export interface SpecialOfferLabelsGroupProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const SpecialOfferLabelsGroup = ({
  children,
  className,
}: SpecialOfferLabelsGroupProps) => {
  const childrenArr = Children.toArray(children).filter(child => !!child);

  if (!childrenArr.length) return null;

  return <div className={cls(styles.container, className)}>{childrenArr}</div>;
};
