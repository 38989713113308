import dynamic from 'next/dynamic';
import { PriceProps } from './Price';

// TODO: remove after we have results of an AB-test: https://swapfiets.visualstudio.com/Digital/_workitems/edit/23576
export const Price = dynamic<PriceProps>(
  () =>
    import('@/components/local/BikesOverview/components/Price/Price').then(
      mod => mod.Price
    ),
  { ssr: false }
);
