import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { A } from '@/components/atoms/A/A';
import { ImageContainer } from '@/components/atoms/ImageContainer/ImageContainer';
import { ProductTag } from '@/components/atoms/ProductTag/ProductTag';
import { SpecialOfferLabel } from '@/components/atoms/SpecialOfferLabel/SpecialOfferLabel';
import { H2, P } from '@/components/atoms/Typography/Typography';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import { SpecialOfferLabelsGroup } from '@/components/molecules/SpecialOfferLabelsGroup/SpecialOfferLabelsGroup';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { FEATURE_GATES, checkGate } from '@/lib/statsig';
import { TransformedAssetCategoryResult } from '@/types/TransformedAssetCategoryResults';
import { getPromoLabelTranslationKey } from '@/utils/getPromoLabelText';
import { isEbike } from '@/utils/isEbike';
import { getProductStudentPrice } from '@/utils/productPrice';
import { Price } from '../Price';
import styles from './ProductCard.module.scss';

export interface ProductCardProps {
  className?: string;
  currency: string;
  locale: string;
  product: TransformedAssetCategoryResult;
  onClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
}

export const ProductCard = ({
  className,
  currency,
  locale,
  product,
  onClick,
}: ProductCardProps) => {
  const { t } = useTranslation([
    nameSpacesConfig.common,
    nameSpacesConfig.product,
  ]);
  const { query } = useRouter();

  const { countryCode } = getCountryConfigFromLocale(locale);

  const hasSpecialOffer = Boolean(product.rates.specialOffer);
  const hasReducedPrice = checkGate(
    {
      custom: {
        country: countryCode,
        product: product.productConfig.id,
      },
    },
    FEATURE_GATES.priceReduction
  );
  const hasNoSignUpFee = checkGate(
    {
      custom: {
        country: countryCode,
        product: product.productConfig.id,
      },
    },
    FEATURE_GATES.noSignUpFee
  );
  const labelTranslationKey = getPromoLabelTranslationKey(
    product.productConfig.translationKey,
    hasReducedPrice,
    hasNoSignUpFee
  );
  const showPromoLabel = hasSpecialOffer || hasReducedPrice || hasNoSignUpFee;
  const showStudentLabel = Boolean(getProductStudentPrice(product.rates));

  return (
    <Link
      href={{
        pathname: '/bikes/[product]',
        query: { ...query, product: product.productConfig.id },
      }}
      passHref
      prefetch={false}
    >
      <div
        className={cls(styles.productCard, className)}
        onClick={onClick}
        aria-hidden="true"
        data-test-id="bike-overview-card"
      >
        <ImageContainer ratioWidth={4} ratioHeight={3}>
          <CloudinaryImage
            src={product.productConfig.image}
            alt={product.productConfig.name}
            layout="fill"
            sizes={'(max-width: 768px) 100vw,' + '360px'}
            objectFit="contain"
          />
          {isEbike(product.assetCategoryCode) && (
            <ProductTag
              className={styles.productTag}
              text={t(`common:electric.${product.productConfig.id}`)}
            />
          )}
          <SpecialOfferLabelsGroup className={styles.labelsGroup}>
            {showPromoLabel && (
              <SpecialOfferLabel
                color={hasReducedPrice ? 'secondary' : 'primary'}
                text={t(labelTranslationKey)}
                version="body2"
              />
            )}
            {showStudentLabel && (
              <SpecialOfferLabel
                color="lightBlue"
                text={t('common:specialOffers.student')}
                version="body2"
              />
            )}
          </SpecialOfferLabelsGroup>
        </ImageContainer>
        <H2 className={styles.productName}>{product.productConfig.name}</H2>
        <P className={styles.productDescription}>
          {t(`product:${product.productConfig.translationKey}.subtitle`)}
        </P>
        <div className={styles.productCardFooter}>
          <div className={styles.priceWrapper}>
            <Price product={product} locale={locale} currency={currency} />
          </div>
          <A className={styles.moreDetailsBtn} color="primary">
            {t('common:product_state.more')}
          </A>
        </div>
      </div>
    </Link>
  );
};
