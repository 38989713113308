import cls from 'clsx';
import React from 'react';
import { Button } from '@/components/atoms/Button/Button';
import { Container } from '@/components/atoms/Layout/Layout';
import { Ul } from '@/components/atoms/List/List';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H2, H3, Li, P } from '@/components/atoms/Typography/Typography';
import styles from './HowItWorks.module.scss';

interface Props {
  buttonText?: string;
  classLeft?: string;
  classname?: string;
  classRight?: string;
  headingText: string;
  howItWorksList: [JSX.Element, string, string | JSX.Element][];
  onClick?: () => void;
}
export const HowItWorks = ({
  buttonText,
  classLeft,
  classname,
  classRight,
  headingText,
  howItWorksList,
  onClick,
}: Props) => (
  <div className={cls(styles.container, classname)}>
    <Container>
      <TextAndImage
        wrapOnTop="left"
        classLeft={cls(classLeft, styles.left)}
        classRight={cls(classRight, 'typography', styles.right)}
        left={<H2 version="level1">{headingText}</H2>}
        right={
          <>
            <Ul>
              {howItWorksList.map(entry => (
                <Li key={entry[1]} className={styles.step}>
                  {entry[0]}
                  <H3 version="level4">{entry[1]}</H3>
                  <P className={styles.desc}>{entry[2]}</P>
                </Li>
              ))}
            </Ul>
            {!!buttonText && !!onClick && (
              <div id={styles.checkButton}>
                <Button
                  onClick={onClick}
                  fullWidth
                  type="button"
                  variant="contained"
                  color="secondary"
                >
                  {buttonText}
                </Button>
              </div>
            )}
          </>
        }
      />
    </Container>
  </div>
);
