import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { useMedia } from 'react-use';
import { Container } from '@/components/atoms/Layout/Layout';
import { H1 } from '@/components/atoms/Typography/Typography';
import { useData } from '@/lib/pageData/pageDataContext';
import { isExperimentActive } from '@/lib/statsig';
import breakpoints from '@/theme/breakpoints.module.scss';
import { TransformedAssetCategoryResults } from '@/types/TransformedAssetCategoryResults';
import styles from './BikesOverview.module.scss';
import { ProductCard } from './components/ProductCard/ProductCard';
import { Slider } from './components/Slider/Slider';

interface Props {
  productList: TransformedAssetCategoryResults;
}

export const BikesOverview = ({ productList }: Props) => {
  const { locale } = useRouter();
  const data = useData<'home' | 'ebikes'>();
  const { t } = useTranslation();
  const isMediumScreen = useMedia(
    `(max-width: ${breakpoints.mediaXL}) and (min-width: ${breakpoints.mediaSM})`,
    false
  );

  const isTestVersion = isExperimentActive('homepageStorytelling');
  const i18nKey = isTestVersion ? 'pages:home.test' : 'pages:home';

  return (
    <section className={styles.wrapper} data-test-id="bikes-overview">
      <Container className={styles.container}>
        <H1 className={styles.heading}>
          {t(`${i18nKey}.bikesOverview.headingMain`)}
        </H1>
        {isMediumScreen ? (
          <Slider
            currency={data.currency}
            locale={locale!}
            productList={productList}
          />
        ) : (
          <div className={styles.bikesList}>
            {productList.map(product => (
              <ProductCard
                className={styles.productCard}
                key={product.productConfig.id}
                product={product}
                locale={locale!}
                currency={data.currency}
              />
            ))}
          </div>
        )}
      </Container>
    </section>
  );
};
