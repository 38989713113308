import { FaBolt } from '@react-icons/all-files/fa/FaBolt';
import cls from 'clsx';
import React from 'react';
import { P } from '@/components/atoms/Typography/Typography';
import styles from './ProductTag.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  center?: 'onlyDesktop' | 'all';
}

export const ProductTag = ({ className, text, center, ...rest }: Props) => (
  <div
    className={cls(
      styles.electricContainer,
      {
        [styles[`${center}Center`]]: center,
      },
      className
    )}
    {...rest}
  >
    <FaBolt
      className={styles.electricIcon}
      preserveAspectRatio="xMidYMid slice"
    />
    <P version="level5">{text}</P>
  </div>
);
