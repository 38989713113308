import cls from 'clsx';
import React, { useState, useRef, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { Button } from '@/components/atoms/Button/Button';
import { ChevronBlue } from '@/components/Icons/ChevronBlue';
import { TransformedAssetCategoryResults } from '@/types/TransformedAssetCategoryResults';
import { ProductCard } from '../ProductCard/ProductCard';
import styles from './Slider.module.scss';

interface Props {
  currency: string;
  locale: string;
  productList: TransformedAssetCategoryResults;
}

const PRODUCT_CARD_WIDTH = 294;
const ICON_SIZE = 22;

export const Slider = ({ currency, locale, productList }: Props) => {
  const { width, height } = useWindowSize();
  const [translateX, setTranslateX] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const slidesContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [slidesContainerWidth, setSlidesContainerWidth] = useState(0);

  useEffect(() => {
    setContainerWidth(containerRef?.current?.offsetWidth || 0);
    setSlidesContainerWidth(slidesContainerRef?.current?.offsetWidth || 0);
  }, [width, height]);

  const moveLeft = () => {
    if (-translateX > PRODUCT_CARD_WIDTH) {
      setTranslateX(translateX + PRODUCT_CARD_WIDTH);
      return;
    }

    setTranslateX(0);
  };

  const moveRight = () => {
    const slidesContainerDimensions =
      slidesContainerRef?.current!.getBoundingClientRect();
    const distanceToRightEndOfViewPort =
      slidesContainerDimensions.right - containerWidth;

    if (distanceToRightEndOfViewPort < PRODUCT_CARD_WIDTH) {
      return setTranslateX(
        translateX -
          distanceToRightEndOfViewPort +
          containerRef?.current!.getBoundingClientRect().left
      );
    }

    setTranslateX(translateX - PRODUCT_CARD_WIDTH);
  };

  return (
    <div>
      <div
        className={cls(styles.sliderContainer, {
          [styles.sliderContainerCentered]:
            containerWidth > slidesContainerWidth,
        })}
        ref={containerRef}
      >
        {translateX < 0 && (
          <div
            className={cls(styles.fadeEffect, styles.fadeEffectLeft)}
            onClick={() => moveLeft()}
            aria-hidden="true"
          />
        )}
        <div
          ref={slidesContainerRef}
          className={styles.slidesContainer}
          style={{ transform: `translateX(${translateX}px)` }}
        >
          {productList.map(product => (
            <ProductCard
              onClick={event => {
                if (
                  event.currentTarget.getBoundingClientRect().right >
                  window.innerWidth
                ) {
                  event.preventDefault();
                  moveRight();
                  return;
                }

                if (event.currentTarget.getBoundingClientRect().left < 0) {
                  event.preventDefault();
                  moveLeft();
                  return;
                }
              }}
              className={styles.productCard}
              key={product.productConfig.id}
              product={product}
              locale={locale}
              currency={currency}
            />
          ))}
        </div>
        {containerWidth - slidesContainerWidth < translateX && (
          <div
            className={cls(styles.fadeEffect, styles.fadeEffectRight)}
            onClick={() => moveRight()}
            aria-hidden="true"
          />
        )}
      </div>
      <div className={styles.controls}>
        {translateX < 0 && (
          <Button className={styles.buttonLeft} onClick={() => moveLeft()}>
            <ChevronBlue height={ICON_SIZE} />
          </Button>
        )}
        {containerWidth - slidesContainerWidth < translateX &&
          containerWidth < slidesContainerWidth && (
            <Button className={styles.buttonRight} onClick={() => moveRight()}>
              <ChevronBlue height={ICON_SIZE} />
            </Button>
          )}
      </div>
    </div>
  );
};
