import { NON_BR_SPACE } from '@/components/strings';

export const product = {
  power7: 'power-7',
  power1: 'power-1',
  powerPlus: 'power-plus',
  originalPlus: 'original-plus',
  original: 'original',
  deluxe7: 'deluxe-7',
} as const;

export type ProductKeys = (typeof product)[keyof typeof product];

export const productCategories = {
  eBike: 'eBike',
  city: 'city',
  other: 'other',
} as const;

export type ProductCategory =
  (typeof productCategories)[keyof typeof productCategories];

export interface ProductConfig {
  assetCategoryCode: string;
  assetCategoryName: string;
  id: ProductKeys;
  translationKey: keyof typeof product;
  image: string;
  imageConfigure: string;
  imageRecommended: string;
  name: string;
  sliderImages: string[];
  uiName: string;
  productCategory: ProductCategory;
}

export type ProductConfigWithRates = ProductConfig & {
  rates: {
    normal: number;
    specialOffer?: number | null;
  };
};

export type ProductConfigWithOffer = ProductConfig & {
  hasSpecialOffer?: boolean;
};

export const productsConfig: Record<ProductKeys, ProductConfig> = {
  [product.power7]: {
    assetCategoryCode: 'pwr-seven',
    assetCategoryName: 'Power 7',
    id: product.power7,
    translationKey: 'power7',
    image: '/members_web/products/power7/render-power7-list.jpg',
    imageConfigure: '/members_web/products/power7/render-power7-configure.png',
    imageRecommended: '/members_web/products/power7/recommend-power7.jpg',
    productCategory: productCategories.eBike,
    name: 'Power 7',
    sliderImages: [
      '/members_web/products/power7/render-power7-1.jpg',
      '/members_web/products/power7/render-power7-2.jpg',
      '/members_web/products/power7/render-power7-3.jpg',
      '/members_web/products/power7/render-power7-4.jpg',
      '/members_web/products/power7/render-power7-5.jpg',
      '/members_web/products/power7/render-power7-6.jpg',
      '/members_web/products/power7/render-power7-7.jpg',
    ],
    uiName: `Power${NON_BR_SPACE}7`,
  },
  [product.originalPlus]: {
    assetCategoryCode: 'org-one-plus',
    assetCategoryName: 'Original 1+',
    id: product.originalPlus,
    translationKey: 'originalPlus',
    image: '/members_web/products/original-plus/render-original-plus-list.jpg',
    imageConfigure:
      '/members_web/products/original-plus/render-original-plus-configure.png',
    imageRecommended:
      '/members_web/products/original-plus/recommend-original-plus-1.jpg',
    productCategory: productCategories.city,
    name: 'Original',
    sliderImages: [
      '/members_web/products/original-plus/render-original-plus-1.jpg',
      '/members_web/products/original-plus/render-original-plus-2.jpg',
      '/members_web/products/original-plus/render-original-plus-3.jpg',
      '/members_web/products/original-plus/render-original-plus-4.jpg',
      '/members_web/products/original-plus/render-original-plus-5.jpg',
    ],
    uiName: 'Original',
  },
  [product.original]: {
    assetCategoryCode: 'org',
    assetCategoryName: 'Original 1',
    id: product.original,
    translationKey: 'original',
    image: '/members_web/products/original/render-org-list.jpg',
    imageConfigure:
      '/members_web/products/original/render-original-configure.png',
    imageRecommended: '/members_web/products/original/recommend-original.jpg',
    productCategory: productCategories.city,
    name: 'Original',
    sliderImages: [
      '/members_web/products/original/render-original-1.jpg',
      '/members_web/products/original/render-original-2.jpg',
      '/members_web/products/original/render-original-3.jpg',
      '/members_web/products/original/render-original-4.jpg',
    ],
    uiName: 'Original',
  },
  [product.deluxe7]: {
    assetCategoryCode: 'dlu',
    assetCategoryName: 'Deluxe 7',
    id: product.deluxe7,
    translationKey: 'deluxe7',
    image: '/members_web/products/deluxe7/render-deluxe7-list.jpg',
    imageConfigure:
      '/members_web/products/deluxe7/render-deluxe7-configure.png',
    imageRecommended: '/members_web/products/deluxe7/recommend-deluxe.jpg',
    productCategory: productCategories.city,
    name: 'Deluxe 7',
    sliderImages: [
      '/members_web/products/deluxe7/render-deluxe7-1.jpg',
      '/members_web/products/deluxe7/render-deluxe7-2.jpg',
      '/members_web/products/deluxe7/render-deluxe7-3.jpg',
      '/members_web/products/deluxe7/render-deluxe7-4.jpg',
      '/members_web/products/deluxe7/render-deluxe7-5.jpg',
      '/members_web/products/deluxe7/render-deluxe7-6.jpg',
    ],
    uiName: `Deluxe${NON_BR_SPACE}7`,
  },
  [product.power1]: {
    assetCategoryCode: 'pwr-one',
    assetCategoryName: 'Power 1',
    id: product.power1,
    translationKey: 'power1',
    image: '/members_web/products/power1/render-power1-list.jpg',
    imageConfigure: '/members_web/products/power1/render-power1-configure.png',
    imageRecommended: '/members_web/products/power1/recommend-power1.jpg',
    productCategory: productCategories.eBike,
    name: 'Power 1',
    sliderImages: [
      '/members_web/products/power1/render-power1-1.jpg',
      '/members_web/products/power1/render-power1-2.jpg',
      '/members_web/products/power1/render-power1-3.jpg',
      '/members_web/products/power1/render-power1-4.jpg',
      '/members_web/products/power1/render-power1-5.jpg',
      '/members_web/products/power1/render-power1-6.jpg',
      '/members_web/products/power1/render-power1-7.jpg',
      '/members_web/products/power1/render-power1-8.jpg',
    ],
    uiName: `Power${NON_BR_SPACE}1`,
  },
  [product.powerPlus]: {
    assetCategoryCode: 'pwr-plus',
    assetCategoryName: 'Power Plus',
    id: product.powerPlus,
    translationKey: 'powerPlus',
    image: '/members_web/products/power-plus/power-plus-list.png',
    imageConfigure: '/members_web/products/power-plus/power-plus-configure.png',
    imageRecommended: '',
    productCategory: productCategories.eBike,
    name: 'Power Plus',
    sliderImages: [
      '/members_web/products/power-plus/power-plus-pdp-1.png',
      '/members_web/products/power-plus/power-plus-pdp-2.png',
      '/members_web/products/power-plus/power-plus-pdp-3.png',
      '/members_web/products/power-plus/power-plus-pdp-4.png',
      '/members_web/products/power-plus/power-plus-pdp-5.png',
      '/members_web/products/power-plus/power-plus-pdp-6.png',
    ],
    uiName: `Power${NON_BR_SPACE}Plus`,
  },
};
